<script setup lang="ts"></script>

<template>
  <Accordion type="single" collapsible>
    <AccordionItem value="item-1" class="border-transparent no-chevron">
      <AccordionTrigger>
        <p class="leading-1 font-semibold text-sm text-left">How we protect
          your privacy and data
          <Icon name="lucide:chevron-down" size="1.4em" class="inline" />
          <!-- <Icon name="material-symbols-light:arrow-right-alt" size="1.5em" class="inline" /> -->
        </p>
      </AccordionTrigger>
      <!-- TODO: turn this into carousel -->
      <AccordionContent>
        <ul class="my-6 ml-6 list-disc [&>li]:mt-2 text-xs">

          <li><span class="font-semibold">Data Minimization and Purpose Limitation:</span> We only collect
            data necessary for specific project purposes, and clearly define and communicate these intended
            uses to prevent misuse.</li>
          <li><span class="font-semibold">Data Security:</span> We implement robust technical and
            organizational measures to protect your data against unauthorized access, breaches, and misuse.
            Our security practices are regularly reviewed and updated to align with evolving standards and
            threats.</li>
          <li><span class="font-semibold">Data Quality and Integrity:</span> We ensure the accuracy and
            integrity of the data collected and used. Up-to-date records of data sources and transformations
            are maintained to support data quality.</li>
          <li><span class="font-semibold">Risk Management:</span> Regular risk assessments are conducted to
            identify and mitigate potential threats to data security and intellectual property. Intellectual
            property considerations are included in privacy impact assessments.</li>
          <li><span class="font-semibold">De-Identification:</span> Where possible, we use de-identified or
            aggregated data to minimize risks while preserving data utility for analytics purposes.</li>
          <li><span class="font-semibold">Compliance with Legal Standards:</span> We comply with relevant
            legal standards and guidelines, fostering trust and ensuring robust data protection.</li>
          <li><span class="font-semibold">Confidentiality and Intellectual Property Protection:</span> We
            maintain the confidentiality of all participants' information, including commercially sensitive
            information and intellectual property. Our processes ensure that such information is not
            disclosed
            or used inappropriately, in line with probity requirements for public procurement.</li>
        </ul>
      </AccordionContent>
    </AccordionItem>
  </Accordion>
</template>

<style>
[aria-controls^="radix-vue-collapsible-content"] .no-chevron svg {
  display: none;
}
</style>
