<template>
  <!-- <ProgressBar :uppy="uppy" :props="{
    hideAfterFinish: false
  }" /> -->
  <div ref="fileuploadRef" @dragenter.prevent @dragover.prevent @dragleave="onDragLeave"
    v-if="firstUpload && !uploadConsent" class="p-2 max-h-[80vh] overflow-hidden">
    <DrawerHeader class="p-10">
      <DrawerTitle>
        <h3 class="scroll-m-20 text-lg sm:text-xl md:text-2xl font-semibold tracking-tight mb-4 text-primary text-left">
          An important data privacy notice
        </h3>
      </DrawerTitle>
      <DrawerDescription>
        <div class="text-left text-md sm:text-lg md:text-xl">
          <div class="flex flex-col">
            <div class="absolute w-full z-50" id="uppy"></div>
            <p class="leading-7 [&:not(:first-child)]:mt-6 text-primary">
              We use your data to find and match plant species.
              <span class="font-semibold">We will never share or use your data for any other purpose.</span>
            </p>
          </div>
          <div class="flex w-full gap-4  pt-8">
            <div class="flex items-start justify-center">
              <Icon name="material-symbols:shield-lock" size="4em" class="text-primary" />
            </div>
            <div>
              <p class="text-md">Our operations depend on rigorous privacy and security measures. We follow the
                <NuxtLink target="_blank" to="https://www.oaic.gov.au/privacy/privacy-legislation/the-privacy-act">
                  Australian Privacy Act 1988
                </NuxtLink> and the <NuxtLink target="_blank"
                  to="https://www.oaic.gov.au/privacy/australian-privacy-principles">Australian Privacy Principles
                </NuxtLink> to ensure your data is handled in a secure and privacy-compliant manner.
              </p>
            </div>
          </div>
          
          <HowWeProtectPrivacy />
          
        </div>
      </DrawerDescription>
    </DrawerHeader>
    <DrawerFooter class="p-10 ">
      <div class="self-end space-x-2" ref="buttons">

        <Button @click="closeFileUploader" variant="ghost" class="">
          Go back
        </Button>
        <Button @dragenter.prevent @dragover.once.prevent="onDragOver" @dragleave="onDragLeave" @dragend.prevent
          @click="setUploadConsent(true)" class="leading-6">

          Continue {{ consentTimer ? 'in ' + consentTimer : '' }}


        </Button>


      </div>

    </DrawerFooter>
  </div>
  <div v-if="uploadConsent == true" class="p-0 sm:p-2 max-h-[90vh] overflow-hidden">
    <DrawerHeader class="p-5 sm:p-10">
      <DrawerTitle>
        <!-- <h3 class="scroll-m-20 text-lg sm:text-xl md:text-2xl font-semibold tracking-tight mb-4 text-primary text-left">
          <Icon name="material-symbols:shield-lock" size="1.2em" class="text-primary" />
          Secure Upload
        </h3> -->
        <div class="flex items-start flex-row">
          
          <h3
            class="scroll-m-20 text-lg sm:text-xl md:text-2xl font-semibold tracking-tight mb-4 text-primary text-left">
            Choose a document
            <p class="text-left font-normal text-muted-foreground/80 text-[0.8em] sm:text-[0.75em] tracking-tight leading-6 mt-2">
              <span class="text-primary leading-5 sm:leading-7 hidden sm:block">
                We will never share your data or use it for any other purpose.
              </span>
            </p>
          </h3>

        </div>
      </DrawerTitle>
      <DrawerDescription>
        <div class="sm:mt-2 space-y-4 text-left dots">
          <div class="flex flex-col gap-0 sm:gap-8">



            <div class="grid grid-cols-3 px-5 sm:px-0 md:grid-cols-3 gpa-0 sm:gap-2 sm:gap-10">
              <div
                class="flex flex-col items-center p-2 sm:p-4 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-background to-transparent rounded-2xl">
                <Icon name="material-symbols:folder" size="2em" class="text-primary mb-2" />
                <p
                  class="text-center font-regular tracking-tight sm:whitespace-nowrap pb-2 text-primary text-sm sm:text-lg">
                  Files or Folders</p>
                <p class="hidden sm:block text-[0.8em] sm:text-[0.9em] leading-4 text-center font-light max-h-[20%]">Any
                  kind of spreadsheets, images, PDFs, CAD documents, etc</p>
              </div>
              <div
                class="flex flex-col items-center p-4 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-background to-transparent rounded-2xl">
                <Icon name="material-symbols:cloud-upload" size="2em" class="text-primary mb-2" />
                <p
                  class="text-center font-regular tracking-tight sm:whitespace-nowrap pb-2 text-primary text-sm sm:text-lg">
                  Connect to cloud</p>
                <p class="hidden sm:block text-[0.8em] sm:text-[0.9em] leading-4 text-center font-light max-h-[20%]">
                  Transfer directly from Google Drive, OneDrive, Dropbox without consuming your data usage.</p>
              </div>
              <div
                class="flex flex-col items-center p-4 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-background to-transparent rounded-2xl">
                <Icon name="material-symbols:pause-circle" size="2em" class="text-primary mb-2" />
                <p
                  class="text-center font-regular tracking-tight sm:whitespace-nowrap pb-2 text-primary text-sm sm:text-lg">
                  Fast and Reliable</p>
                <p class="hidden sm:block  text-[0.8em] sm:text-[0.9em] leading-4 text-center font-light max-h-[20%]">
                  Pause/resume large
                  uploads and intermittent connection.
                </p>
              </div>

            </div>
            <div class="flex flex-col items-center">
              <h3
                class="scroll-m-20 text-lg sm:text-xl md:text-2xl font-semibold tracking-tight mb-0 text-primary text-center w-full bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-background to-transparent">
              </h3>
              <div
                class="bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-background to-transparent">
                <HowWeProtectPrivacy />
              </div>
            </div>


            <ClientOnly>
              <Dashboard 
                v-if="isUppyReady"
                keep-alive 
                :nuxt-child-key="$route.fullPath" 
                v-motion-slide-visible-bottom 
                ref="dashboardRef"
                :class="['w-full squircle-40 p-2 sm:p-8 bg-transparent !p-0 rounded-lg mb-12']" 
                :uppy="$uppy"
                :props="dashboardOptions" 
              />
            </ClientOnly>

          </div>
        </div>
        <div
          class="absolute bottom-2 right-0 w-[400px] whitespace-nowrap text-right pr-8 h-8 flex items-center space-x-2 justify-end">
          <Button variant="ghost" size="xs" class="p-1 mr-10 text-xs leading-none" @click="clearFiles">
            <Icon name="material-symbols:delete" size="1.6em" class="text-muted-foreground mr-1"  />
            Clear uploads
          </Button>
          <Checkbox :checked="hideFileUploadOnNewTab" @update:checked="setHideFileUploadOnNewTab($event)" id="dont-show-again" size="xs" />
          <label for="dont-show-again"
            class="text-xs leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
            Don't show again
          </label>
        </div>
        <!-- left side -->
        <div class="absolute bottom-2 left-0 w-[400px] whitespace-nowrap text-left pl-8 h-8 flex items-center space-x-2 justify-start">
          <div class="flex items-center space-x-2">
            <Checkbox 
              id="use-cache" 
              :checked="useCache"
              @update:checked="setUseCache"
              class="data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground" 
            />
            <label 
              for="use-cache"
              class="text-xs leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer"
            >
              Use cached results
            </label>
          </div>
        </div>
        
      </DrawerDescription>
    </DrawerHeader>
  </div>

</template>

<script setup lang="ts">
import type { Uppy } from '@uppy/core'
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

const nuxtApp = useNuxtApp()
const $uppy = nuxtApp.$uppy as Uppy
const fileuploadRef = ref()
const dashboardRef = ref()
const { height } = useElementBounding(fileuploadRef)
const uiStore = useUiStore()
const { hideFileUploadOnNewTab, firstUpload, uploadConsent, preventDragOver } = storeToRefs(uiStore)
const { setHideFileUploadOnNewTab, toggleBottomSheet, setUploadConsent, setBottomDrawerHeight} = uiStore

// Ensure Uppy is available before mounting Dashboard
const isUppyReady = ref(false)

onMounted(() => {
  setBottomDrawerHeight(height.value)
  // Only set ready when Uppy is actually available
  if ($uppy) {
    isUppyReady.value = true
  }
})

import {
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@/components/ui/drawer'


// TODO https://uppy.io/docs/locales/

import { useProjectData } from '@/composables/projectData'
import { dummyProjectTitle } from '@/lib/utils'
import { useElementBounding } from '@vueuse/core'


const userStore = useUserStore()
const dockStore = useDockStore()
const uppyStore = useUppyStore()

const { useCache } = storeToRefs(dockStore)
const { setUseCache } = dockStore

const { removeFileRequested } = storeToRefs(uppyStore)
const { setRemoveFileRequested } = uppyStore

const colorMode = useColorMode()


const dashboardOptions = {
  theme: colorMode.preference,
  inline: true,
  metaFields: [{ id: 'name', name: 'Name', placeholder: 'File name' }],
  proudlyDisplayPoweredByUppy: false,
  closeAfterFinish: false,
  disableStatusBar: false,
  showRemoveButtonAfterComplete: true
}

const consentTimer = ref(0)


const consentTimerInterval = ref<ReturnType<typeof setInterval> | null>(null)

const onDragOver = (e: DragEvent) => {
  if (preventDragOver.value) return
  e.preventDefault()
  e.stopPropagation()
  consentTimer.value = 5
  consentTimerInterval.value = setInterval(() => {
    consentTimer.value--
  }, 1000)
}

const onDragLeave = () => {
  if (consentTimerInterval.value) {
    clearInterval(consentTimerInterval.value)
  }
  consentTimer.value = 0
}



const remove = () => {
  if (import.meta.client) {
    const files = $uppy.getFiles()
    if (files.length > 0) {
      const lastFile = files[files.length - 1]
      if (lastFile) {
        $uppy.removeFile(lastFile?.id)
      }
    }
  }
}


const clearFiles = () => {
  if ($uppy) {
    // Cancel all uploads but don't close the dialog
    $uppy.cancelAll()
    // Reset the files only
    // $uppy.reset()
    
    // Reset store state
    const uppyStore = useUppyStore()
    uppyStore.resetUploads()
    
    // Prevent the dashboard from closing
    // nextTick(() => {
    //   if (dashboardRef.value?.plugin) {
    //     dashboardRef.value.plugin.setOptions({
    //       closeAfterFinish: false
    //     })
    //   }
    // })
  }
}


watch(removeFileRequested, (val) => {
  if (val) {
    remove()
    setRemoveFileRequested(false)
  }
}, { immediate: true })


const closeFileUploader = () => {
  toggleBottomSheet(false)
  if (dashboardRef.value?.plugin) {
    dashboardRef.value.plugin.requestCloseModal()
  }
}

</script>

<style >
  

.uppy-StatusBar-actionBtn--done {
  display: none !important;
}

button[id^="radix-vue-accordion-trigger"]:has(> *) {
  border: none !important;
}



.uppy-Dashboard .uppy-Dashboard--animateOpenClose .uppy-size--md .uppy-size--height-md .uppy-Dashboard--isInnerWrapVisible,
.uppy-Dashboard-AddFiles,
.uppy-Dashboard-innerWrap,
.uppy-Dashboard-inner,
.uppy-Dashboard-overlay {
  border: none !important;

}

.uppy-Dashboard-AddFiles {
  border: none !important;
}

.uppy-DragDrop--isDraggingOver,
.uppy-DragDrop--isDraggingOver * {
  background-color: transparent !important;
}




[data-uppy-theme=dark] div[class*="uppy-DashboardContent"] {
  color: white !important;
}

[data-uppy-theme=light] div[class*="uppy-DashboardContent"] {
  color: white !important;
}


[data-uppy-theme=dark] .uppy .uppy-Informer{
  color: white !important;
}

[data-uppy-theme=dark] p,[data-uppy-theme=dark] span, [data-uppy-theme=dark] a, [data-uppy-theme=dark] li, [data-uppy-theme=dark] div {
  color: white !important;
}

[data-uppy-theme=dark] .uppy-Dashboard-files, [data-uppy-theme=dark] .uppy-StatusBar.is-complete{
  background-color: #020817 !important;
}

[data-uppy-theme=dark] .uppy-Dashboard-files,{
  --sb-track-color: #17203e;
  --sb-thumb-color: #4a5680;
  --sb-size: 12px;
}

[data-uppy-theme=dark] .uppy-Dashboard-files::-webkit-scrollbar {
  width: var(--sb-size)
}

[data-uppy-theme=dark] .uppy-Dashboard-files::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 13px;
}

[data-uppy-theme=dark] .uppy-Dashboard-files::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 13px;

}

@supports not selector(::-webkit-scrollbar) {
  [data-uppy-theme=dark] .uppy-Dashboard-files {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}


[data-uppy-theme=light] .uppy-Dashboard-files {
  --sb-track-color: #d5d5d5;
  --sb-thumb-color: #2d2d2d;
  --sb-size: 12px;
}

[data-uppy-theme=light] .uppy-Dashboard-files::-webkit-scrollbar {
  width: var(--sb-size)
}

[data-uppy-theme=light] .uppy-Dashboard-files::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 13px;
}

[data-uppy-theme=light] .uppy-Dashboard-files::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 13px;

}

@supports not selector(::-webkit-scrollbar) {
  [data-uppy-theme=light] .uppy-Dashboard-files {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  }
}


[data-uppy-theme=dark] .uppy-Dashboard-dropFilesHereHint,
[data-uppy-theme=dark] .uppy-Dashboard-AddFiles-title,
[data-uppy-theme=dark] .uppy-Dashboard-note,
.uppy-Dashboard-uploadCount,
[data-uppy-theme=dark] .uppy-Dashboard-dropFilesHereHint,
.uppy-Dashboard-dropFilesHereHint {
  color: var(--primary) !important;
}

.uppy-Dashboard-AddFiles-list {}

.uppy-Dashboard-innerWrap,
.uppy-Dashboard-inner {
  border: none !important;
}

.uppy-Dashboard-poweredBy {
  border: none !important;
}

a[href="https://uppy.io"] {
  display: none !important;
}


.uppy-size--md .uppy-Dashboard-AddFiles-list {
  /* justify-content: start; */
  /* padding-left: 2vw; */
}

.uppy-c-btn {
  filter: grayscale(1) brightness(1.6) !important;
  /* color: white !important; */
}

.uppy-Root * {
  font-family: 'Inter', sans-serif !important;
  letter-spacing: -0.005em !important;
  color: var(--primary) !important;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  
  /* filter: grayscale(1); */
}

.uppy-Root button svg.lucide-chevron-down-icon {
  display: none !important;
}

.uppy-Root img {
  pointer-events: none;
}

.uppy-Root svg {
  filter: grayscale(1)
}

.uppy-DashboardTab-inner {
  box-shadow: none !important;
  background-color: transparent;
}


.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
  background-color: var(--primary) !important;
}

.uppy-DashboardContent-title {
  display: none !important;
}

.uppy-Dashboard-browse:hover,
.uppy-Dashboard-browse:focus {
  /* text-decoration: none !important; */
  /* border-bottom: none !important; */

  border-width: 2px;
}

@media (max-width: 768px) {
  .uppy-Dashboard-browse {
    border-color: var(--primary) !important;
    border-width: 2px;
  }
}


.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  @apply bg-primary !important;
}

*[class*="actionBtn"] {
  @apply text-background !important;
}

*[class*="actionsBtn"]:not(.uppy-c-btn-link) {
  @apply text-background !important;
  @apply bg-primary !important;
}

.uppy-DragDrop-note,
.uppy-Dashboard-AddFiles-title {
  @apply text-primary !important;
}

.uppy-c-btn-link {
  background: none;
  @apply text-primary !important;
}

*:focus {
  box-shadow: none !important;
  outline: 0 !important;

}

.uppy-Dashboard-FileCard-preview,
.uppy-Dashboard-Item-previewInnerWrap {
  @apply bg-transparent !important;
}

.uppy-Dashboard-FileCard-actions {
  border: none !important;
}

.uppy-DashboardContent-bar {
  border: none !important;
}

.uppy-DragDrop--isDragDropSupported {
  border: 2px solid var(--primary) !important;
}

.uppy-DragDrop--isDraggingOver {
  background: var(--primary) !important;
  border: 2px solid var(--background) !important;
}

.uppy-DragDrop--isDraggingOver .uppy-DragDrop-arrow {
  /* fill: #939393; */
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {}

.uppy-StatusBar:not([aria-hidden=true]).is-waiting {

  border-top: none !important;
}

.uppy-Informer-animated * {
  color: white !important;
}

.uppy-Dashboard-files{
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  background-color: white !important;
}

[data-uppy-theme=dark] .uppy-Dashboard-files{
  background-color: #020817 !important;
}

.uppy-DashboardContent-bar {
  @apply bg-background/80 !important;
}

.uppy-Dashboard-filesInner {
  @apply bg-background/80 !important;
}

.uppy-Dashboard-AddFiles-title {
  display: none !important;
}


.dots {
  background-image: radial-gradient(circle, #cdcdcd 1px, transparent 1px);
  background-position: 0 0;
  background-size: 14px 22px;
}

.dark .dots {
  background-image: radial-gradient(circle, #17203e 1px, transparent 1px);
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-dropFilesHereHint {
  background-image: url('data: image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iY3VycmVudENvbG9yIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHRleHQtYWxpYXNlPSJvYmplY3RzIiBzdHJva2UtbGluZW5vaXNlPSJvYmplY3RzIiBzdHJva2Utd2lkdGg9IjEuNSIgZHNTYyA2LjVoOS43NWMyLjEwNyAwIDMuMTYgMCAzLjkxNy41MDZhMyAzIDAgMCAxIC44MjcuODI3Yy40NjQuNjk0LjUwMyAxLjQyNy41MDYgMy4xNjdNMTIgNi41bC0uNjMzLTEuMjY3Yy0uNTI1LTEuMDUtMS4wMDUtMi4xMDYtMi4xNjgtMi41NDJDOC42OSAyLjUgOC4xMDggMi41IDYuOTQ0IDIuNWMtMS44MTYgMC0yLjcyNCAwLTMuNDA2LjM4QTMgMyAwIDAgMCAyLjM4IDQuMDM4QzIgNC43MiAyIDUuNjI4IDIgNy40NDRWMTAuNWMwIDQuNzE0IDAgNy4wNzEgMS40NjQgOC41MzVDNC44MjIgMjAuMzk0IDYuOTQ0IDIwLjQ5MyAxMSAyMC41aDJtNS41LTd2OG0wLThjLS43IDAtMi4wMDkgMS45OTQtMi41IDIuNW0yLjUtMi41Yy43IDAgMi4wMDkgMS45OTQgMi41IDIuNSIgY29sb3I9ImN1cnJlbnRDb2xvciIvPjwvc3ZnPg==');
  background-size: 70px;
  color: var(--svg-color) !important;
  opacity: 0.7;
  --svg-color: var(--primary);
  border: 2px solid var(--primary) !important;
}

.uppy-u-reset.uppy-c-btn.uppy-DashboardTab-btn{
  background-color: var(--primary) !important;
}

div.uppy-DashboardTab-inner{
  background-color: var(--primary) !important;
}

.uppy-size--md .uppy-Provider-authTitle {
  color: var(--primary) !important;
}



</style>
